import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { get, differenceBy, slice, concat } from 'lodash';
import { SEO } from '../components/common/seo';
import { getSchema } from '../common/schema';
import { parseArticles, parseBodySections } from '../common/content';
import { ArticleSectionLayout } from '../components/article/section/section.types';
import { Sections } from '../components/layout/sections';
import { Theme } from '../theme/color';
import { ArticleDynamicList } from '../components/article/dynamicList';
import { HeadingSize, HeadingVariant } from '../components/common/heading/heading.types';

const FEATURED_SIZE = 3;

export const query = graphql`
  query SectionQuery($id: String!, $originalId: String!, $topics: [String]!, $categories: [String]!, $initialSize: Int!) {
    settings: site {
      siteMetadata {
        siteUrl
      }
    }
    site: datoCmsSite {
      ...SiteInformation
    }
    page: datoCmsSection(
      id: { eq: $id }
    ) {
      model {
        apiKey
      }
      meta {
        ...PageMeta
      }
      seo {
        ...PageSEO
      }
      locale
      slug
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      name
      featured {
        ...ArticleCard
      }
      body {
        ... on DatoCmsTopic {
          model {
            apiKey
          }
          locale
          slug
          id
          originalId
          name
        }
        ... on DatoCmsCategory {
          model {
            apiKey
          }
          locale
          slug
          id
          originalId
          name
          theme
        }
      }
      headingMore
    }
    articles: allDatoCmsArticle(
      filter: {slug: {ne: null}, section: {id: {eq: $id}}},
      sort: {fields: meta___firstPublishedAt, order: DESC},
      limit: $initialSize
    ) {
      nodes {
        ...ArticleCard
      }
      totalCount
    }
    versions: allDatoCmsSection(
      filter: {slug: {ne: null}, originalId: {eq: $originalId}}
    ) {
      nodes {
        slug
        locale
        model {
          apiKey
        }
      }
    }
    articlesByTopic: allDatoCmsArticle(
      filter: {slug: {ne: null}, section: {id: {eq: $id}}, topics: {elemMatch: {id: {in: $topics}}}},
      sort: {fields: meta___firstPublishedAt, order: DESC}
    ) {
      group(
        field: topics___originalId,
        limit: 3
      ) {
        nodes {
          ...ArticleCard
        }
        originalId:fieldValue
      }
    }
    articlesByCategory: allDatoCmsArticle(
      filter: {slug: {ne: null}, section: {id: {eq: $id}}, category: {id: {in: $categories}}},
      sort: {fields: meta___firstPublishedAt, order: DESC}
    ) {
      group(
        field: category___originalId,
        limit: 10
      ) {
        nodes {
          ...ArticleCard
        }
        originalId:fieldValue
      }
    }
  }
`;

const Section = ({ data, pageContext }: any) => {
  const settings = get(data, 'settings.siteMetadata');
  const page = get(data, 'page');
  const site = get(data, 'site');
  
  const versions = get(data, 'versions.nodes');
  
  const articlesByTopic = get(data, 'articlesByTopic.group');
  const articlesByCategory = get(data, 'articlesByCategory.group');

  const articles = get(data, 'articles.nodes');
  const articlesTotalCount = get(data, 'articles.totalCount',0);

  if (!page || !settings || !site) return null;

  const schema = getSchema({ settings, site, page, pageContext });

  const curatedArticles = get(page,'featured',[]).filter(article => article.slug);

  const featuredArticles = concat(curatedArticles,articles.slice(0,FEATURED_SIZE - curatedArticles.length));
  const remainingArticles = differenceBy(articles, featuredArticles, 'id');
  const moreArticles = slice(remainingArticles,0,pageContext.initialSize);


  const featured = {
    heading: page.name,
    headingSize: HeadingSize.LARGE,
    headingVariant: HeadingVariant.OVERLINE,
    articles: parseArticles(featuredArticles),
    layout: ArticleSectionLayout.FEATURED,
    theme: Theme.WHITE,
  }

  const body = [featured,...parseBodySections(page.body,{articlesByTopic,articlesByCategory})];

  return (
    <Fragment>
      <SEO 
        tags={page.seoMetaTags}
        favicon={site.faviconMetaTags}
        siteURL={settings.siteUrl}
        schema={schema}
        isArticle={false}
        page={page}
        versions={versions}
      />
      <Sections content={body} />
      {moreArticles.length > 0 && <ArticleDynamicList heading={page.headingMore} headingSize={HeadingSize.SMALL} headingVariant={HeadingVariant.OVERLINE} items={parseArticles(moreArticles)} excludeOriginalIds={concat(featuredArticles.map(a => a.originalId),moreArticles.map(a => a.originalId))} sectionOriginalId={pageContext.originalId} total={Math.max(articlesTotalCount - pageContext.initialSize,0)} paginationSize={pageContext.paginationSize} />}
    </Fragment>
  );
};

export default Section;