import React from 'react';
import { Theme, ThemeType } from '../../../theme/color';
import { ArticleSection } from '../../article/section';

export const Sections = ({content,defaultTheme=Theme.WHITE}) => {
  let current: any;
  let previous;

  let currentTheme: ThemeType;
  let previousTheme: ThemeType;

  return (
    <>
      {content.map((section,index) => {
        
        if(section && section.articles && section.articles.length > 0){
          previous = current;
          current = section;

          section.theme = section.theme || defaultTheme;

          previousTheme = currentTheme;
          currentTheme = section.theme;

          return <ArticleSection key={index} {...section} borderTop={currentTheme === previousTheme} />
        }
        
        return null;
      })}
    </>
  )
};