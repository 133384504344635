import { RefObject, useEffect, useRef, useState } from 'react';

export const useDraggableScroll = (ref: RefObject<HTMLElement>,
  options: {
  direction?: 'vertical' | 'horizontal' | 'both';
  } = { direction: 'both' }) => {

  const { direction } = options;
  let velocityY = 0;
  let velocityX = 0;

  // The initial position (scroll progress and mouse location) when the mouse is pressed down on the element
  let initialPosition = { scrollTop: 0, scrollLeft: 0, mouseX: 0, mouseY: 0 };

  const mouseMoveHandler = (event: { clientX: number; clientY: number }) => {
    if (ref.current) {
      // Calculate differences to see how far the user has moved
      const dx = event.clientX - initialPosition.mouseX;
      const dy = event.clientY - initialPosition.mouseY;

      if (ref.current) ref.current.style.pointerEvents = 'none';

      // Scroll the element according to those differences
      if (direction !== 'horizontal'){
        const previousY = ref.current.scrollTop;
        ref.current.scrollTop = initialPosition.scrollTop - dy;
        velocityY = ref.current.scrollTop - previousY;
      }
        
      if (direction !== 'vertical'){
        const previousX = ref.current.scrollLeft;
        ref.current.scrollLeft = initialPosition.scrollLeft - dx;
        velocityX = ref.current.scrollLeft - previousX;
      }
    }
  };

  const wheelHandler = (event) => {
    stopMomentum();
  }

  const mouseUpHandler = (event) => {

    document.removeEventListener('wheel', wheelHandler);

    // Return to cursor: grab after the user is no longer pressing
    if (ref.current) ref.current.style.cursor = 'grab';
    if (ref.current) ref.current.style.pointerEvents = 'auto';

    document.body.style.cursor = 'default';

    // Remove the event listeners since it is not necessary to track the mouse position anymore
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);

    startMomentum();
  };

  const onMouseDown = (event: { clientX: number; clientY: number }) => {
    stopMomentum();

    document.addEventListener('wheel', wheelHandler);
    
    if (ref.current) {
      // Save the position at the moment the user presses down
      initialPosition = {
        scrollLeft: ref.current.scrollLeft,
        scrollTop: ref.current.scrollTop,
        mouseX: event.clientX,
        mouseY: event.clientY,
      };

      // Show a cursor: grabbing style and set user-select: none to avoid highlighting text while dragging
      document.body.style.cursor = 'grabbing';
      ref.current.style.userSelect = 'none';
      

      // Add the event listeners that will track the mouse position for the rest of the interaction
      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
    }
  };

  var velX = 0;
  var momentumID;

  
  const startMomentum = () => {
    cancelAnimationFrame(momentumID);
    momentumID = requestAnimationFrame(updateMomentum);
  }
  const stopMomentum = () => {
    cancelAnimationFrame(momentumID);
  }
  const updateMomentum = () => {
    if (ref.current) {
      if (direction !== 'horizontal'){
        
        ref.current.scrollTop += velocityY;
        velocityY *= 0.95;
      }
        
      if (direction !== 'vertical'){
        ref.current.scrollLeft += velocityX;
        velocityX *= 0.95;
      }
      
      if (Math.abs(velocityX) > 0.5 || Math.abs(velocityY) > 0.5){
        momentumID = requestAnimationFrame(updateMomentum);
      }
    }
  }

  return {onMouseDown}
  
}