import { get } from "lodash";


export const loadArticles =  async (signal,locale,{excludeOriginalIds=[],sectionOriginalId,categoryOriginalId,topicOriginalId},{first,skip}) => {
  const response = await fetch(
    'https://graphql.datocms.com/',
    {
      method: 'POST',
      signal,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${process.env.GATSBY_DATOCMS_API_TOKEN}`,
      },
      body: JSON.stringify({
        query: `{
          allArticles(
            locale: ${locale.replace("-","_")}
            ${first ? `,first: ${first}` : ``}
            ${skip ? `,skip: ${skip}` : ``}
            ,filter: {
              slug: {neq: null},
              id: {notIn: [${excludeOriginalIds.map(e => `"${e}"`)}]}
              ${sectionOriginalId ? `,section: {eq: "${sectionOriginalId}"}` : ``}
              ${categoryOriginalId ? `,category: {eq: "${categoryOriginalId}"}` : ``}
              ${topicOriginalId ? `,topics: {eq: "${topicOriginalId}"}` : ``}
            },
            orderBy: _firstPublishedAt_DESC
          ){
            id
            slug
            title
            description
            topics {
              name
              slug
            }
            category {
              name
              slug
              action
            }
            _modelApiKey
            _firstPublishedAt
            thumbnail {
              alt
              url(imgixParams: {ar: "16:9", fit: crop, crop: focalpoint})
              responsiveImage(imgixParams: {ar: "16:9", fit: crop, crop: focalpoint}) {
                srcSet
                src
                sizes
                width
                height
              }
            }
            media {
              ... on MediaAudioRecord {
                _modelApiKey
                duration
              }
              ... on MediaVideoRecord {
                _modelApiKey
                duration
              }
              ... on MediaImageRecord {
                _modelApiKey
              }
            }
          }
        }`
      }),
    }
  )

  if(response.ok){
    const data = await response.json();
    return get(data,"data.allArticles");
  }

  return [];
}

export const loadUpdates =  async (signal,locale,{excludeOriginalIds=[]},{first,skip}) => {
  const response = await fetch(
    'https://graphql.datocms.com/',
    {
      method: 'POST',
      signal,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${process.env.GATSBY_DATOCMS_API_TOKEN}`,
      },
      body: JSON.stringify({
        query: `{
          allUpdates(
            locale: ${locale.replace("-","_")}
            ${first ? `,first: ${first}` : ``}
            ${skip ? `,skip: ${skip}` : ``}
            ,filter: {
              date: {neq: null},
              id: {notIn: [${excludeOriginalIds.map(e => `"${e}"`)}]}
            },
            orderBy: date_DESC
          ){
            date
            stories {
              url
              publication {
                name
                url
                logo {
                  alt
                  responsiveImage(imgixParams: { fit: clip, w: "168", h: "24", dpr: 2 }) {
                    srcSet
                    src
                    sizes
                    width
                    height
                  }
                }
              }
              headline
            }
          }
        }`
      }),
    }
  )

  if(response.ok){
    const {data} = await response.json();
    return get(data,"allUpdates");
  }

  return [];
}