import React, { useRef, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { useDraggableScroll } from '../../../hooks/useDraggableScroll';
import { getTheme, Theme } from '../../../theme/color';
import { Heading } from '../../common/heading';
import { ArticleCard } from '../card';

import { Container, Group, Item, HeaderContainer, HeaderContent, BodyContainer, BodyContent, BodyInner } from './section.styles';
import { ArticleSectionLayout, ArticleSectionProps } from './section.types';

export const ArticleSection = ({originalId,id,heading,headingSize,headingVariant,link,articles,layout,theme,borderTop,borderBottom}:ArticleSectionProps) => {
  
  const groups = layout === ArticleSectionLayout.FEATURED || layout === ArticleSectionLayout.EDITORIAL || layout === ArticleSectionLayout.EDITORIAL_REVERSE ? [articles.slice(0,1),articles.slice(1)] : articles.map(article => [article]);

  const scrollableRef = useRef();
  const draggleScroll = layout === ArticleSectionLayout.SLIDER ? useDraggableScroll(scrollableRef,{direction:'horizontal'}) : undefined;

  return (
    <ThemeProvider theme={theme ? getTheme(theme) : getTheme(Theme.WHITE)}>
      <Container layout={layout} count={articles.length} borderTop={borderTop} borderBottom={borderBottom}>
        <HeaderContainer>
          <HeaderContent>
            <Heading link={link} size={headingSize} variant={headingVariant}>{heading}</Heading>
          </HeaderContent>
        </HeaderContainer>
        <BodyContainer ref={scrollableRef} onMouseDown={draggleScroll ? draggleScroll.onMouseDown : undefined}>
          <BodyContent>
            <BodyInner>
              {groups.map((group,groupIndex) => (
                <Group key={groupIndex}>
                  {group.map((props, itemIndex) => <Item key={itemIndex}><ArticleCard groupId={originalId || id} {...props} featured={(layout === ArticleSectionLayout.FEATURED || layout === ArticleSectionLayout.EDITORIAL || layout === ArticleSectionLayout.EDITORIAL_REVERSE) && groupIndex === 0} vertical={layout === ArticleSectionLayout.SLIDER || (layout === ArticleSectionLayout.FEATURED && groupIndex === 0)} /></Item>)}
                </Group>
              ))}
            </BodyInner>
          </BodyContent>
        </BodyContainer>
      </Container>
    </ThemeProvider>
  )
};