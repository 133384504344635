import React, { useEffect, useState } from 'react';
import { ArticleDynamicListProps } from './dynamicList.types';
import { ArticleList } from '../list';
import { loadArticles } from '../../../services/datocms';
import { concat } from 'lodash';
import { useLocale } from '../../../context/locale';
import { parseArticles } from '../../../common/content';

export const ArticleDynamicList = ({heading,headingSize,headingVariant,items,layout,excludeOriginalIds=[],sectionOriginalId=undefined,categoryOriginalId=undefined,topicOriginalId=undefined,buttonLabel,total=0,pageSize=2,borderTop,borderBottom}:ArticleDynamicListProps) => {
  const locale = useLocale();
  const [loadedItems, setLoadedItems] = useState([]);
  const [active, setActive] = useState(total > 0);

  const controller = new AbortController();
  
  const handleButton = async () => {
    if(active){
      setActive(false);
      const articles = await loadArticles(controller.signal,locale,{excludeOriginalIds, sectionOriginalId, categoryOriginalId, topicOriginalId},{first:pageSize,skip:loadedItems.length});
      setLoadedItems(concat(loadedItems,parseArticles(articles,{locale})));
      setActive(true);
    }
  }

  useEffect(() => {
    return () => {
      // Cleanup
      controller.abort();
    };
  }, []);

  return (
    <ArticleList headingSize={headingSize} headingVariant={headingVariant} heading={heading} layout={layout} items={concat(items,loadedItems)} buttonLabel={loadedItems.length < total ? buttonLabel : undefined} onButton={handleButton} borderTop={borderTop} borderBottom={borderBottom} />
  )
};