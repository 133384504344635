import { Link } from 'gatsby';
import styled from 'styled-components';
import ColorTransform from 'color';
import { Color, getColor } from '../../../theme/color';
import { BLOCK_LAYOUT, CONTAINER, getColumnsWidthAsStyle, getMarginAsPixels, getMediaQuery, getSpacerAsPixels, getUnitAsPixels, getVerticalSpacingAsStyle, GRID, GRID_LAYOUT, Layout, ScreenSize, VerticalSpacingType, VerticalSpacingVariant } from '../../../theme/layout';
import { getTypography, Type } from '../../../theme/typography';
import { ArticleSectionLayout } from './section.types';


export const HeadingLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.foreground.primary};
  display: inline-block;
`;

export const Group = styled.div`
  margin-bottom: ${getSpacerAsPixels()};
`

export const HeaderContainer = styled.div`
  ${CONTAINER}
  ${getVerticalSpacingAsStyle(VerticalSpacingType.PADDING,VerticalSpacingVariant.LARGE,{bottom:false})}
  ${getVerticalSpacingAsStyle(VerticalSpacingType.PADDING,VerticalSpacingVariant.SMALL,{top:false})}
`

export const HeaderContent = styled.div`
  ${BLOCK_LAYOUT}
`

export const BodyContainer = styled.div`
  ${CONTAINER}
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  ${getMediaQuery(ScreenSize.MAX)} {
    overflow: visible;
  }
`

export const BodyContent = styled.div`
  ${BLOCK_LAYOUT}
  
  ${getVerticalSpacingAsStyle(VerticalSpacingType.PADDING,VerticalSpacingVariant.LARGE,{top:false})}

  ${getMediaQuery(ScreenSize.MAX)} {
    overflow: auto;
  }
`

export const BodyInner = styled.div`
  ${GRID}
  box-sizing: content-box;
  margin-bottom: ${getSpacerAsPixels({multiplier:-1})};

  *{
    box-sizing: border-box;
  }
`;

export const BodyInnerCopy = styled.div`
  ${GRID}
  margin-bottom: ${getSpacerAsPixels({multiplier:-1})};
  padding-inline-end: ${getMarginAsPixels(ScreenSize.SMALL)};

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    padding-inline-end: ${getMarginAsPixels(ScreenSize.MEDIUM)};
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    padding-inline-end: ${getMarginAsPixels(ScreenSize.LARGE)};
  }

  ${getMediaQuery(ScreenSize.XLARGE)} {
    padding-inline-end: ${getMarginAsPixels(ScreenSize.XLARGE)};
  }

  ${getMediaQuery(ScreenSize.MAX)} {
    padding-inline-end: 0;
  }
`;

export const Item = styled.div`
  margin-bottom: ${getSpacerAsPixels()};

  &:last-child{
    margin-bottom: 0;
  }
`;

export const Container = styled.section<{layout:ArticleSectionLayout,count:number,borderTop?:boolean,borderBottom?:boolean}>`
  background-color: ${({ theme }) => theme.background.primary === getColor(Color.WHITE) ? "transparent" : theme.background.primary};
  border-top: ${({theme, borderTop}) => borderTop ? `1px solid ${ColorTransform(theme.foreground.secondary).alpha(0.1)}` : `none` };
  border-bottom: ${({theme, borderBottom}) => borderBottom ? `1px solid ${ColorTransform(theme.foreground.secondary).alpha(0.1)}` : `none` };

  ${BodyInner}{
    ${({layout,count}) => layout === ArticleSectionLayout.SLIDER ? `
      width: ${getColumnsWidthAsStyle(ScreenSize.SMALL,2,count)};
      grid-template-columns: repeat(${Math.max(4 * count,2)}, [col-start] 1fr);
      padding-inline-end: ${getMarginAsPixels(ScreenSize.SMALL)};
    `:``}

    ${getMediaQuery(ScreenSize.MEDIUM)} {
      ${({layout,count}) => layout === ArticleSectionLayout.SLIDER ? `
      width: ${getColumnsWidthAsStyle(ScreenSize.MEDIUM,6,Math.max(count,2))};
      grid-template-columns: repeat(${Math.max(6 * count,12)}, [col-start] 1fr);
      padding-inline-end: ${getMarginAsPixels(ScreenSize.MEDIUM)};
    `:``}
    }

    ${getMediaQuery(ScreenSize.LARGE)} {
      ${({layout,count}) => layout === ArticleSectionLayout.SLIDER ? `
      width: ${getColumnsWidthAsStyle(ScreenSize.LARGE,4,Math.max(count,3))};
      grid-template-columns: repeat(${Math.max(4 * count,12)}, [col-start] 1fr);
      padding-inline-end: ${getMarginAsPixels(ScreenSize.LARGE)};
    `:``}
    }

    ${getMediaQuery(ScreenSize.XLARGE)} {
      ${({layout,count}) => layout === ArticleSectionLayout.SLIDER ? `
      width: ${getColumnsWidthAsStyle(ScreenSize.XLARGE,4,Math.max(count,3))};
      grid-template-columns: repeat(${Math.max(4 * count,12)}, [col-start] 1fr);
      padding-inline-end: ${getMarginAsPixels(ScreenSize.XLARGE)};
    `:``}
    }

    ${getMediaQuery(ScreenSize.MAX)} {
      ${({layout,count}) => layout === ArticleSectionLayout.SLIDER ? `
      width: ${getColumnsWidthAsStyle(ScreenSize.MAX,4,Math.max(count,3))};
      grid-template-columns: repeat(${Math.max(4 * count,12)}, [col-start] 1fr);
      padding-inline-end: ${getMarginAsPixels(ScreenSize.MAX)};
    `:``}
    }
  }

  ${Group}{
    grid-column: span 4;

    ${getMediaQuery(ScreenSize.MEDIUM)} {
      grid-column: ${({layout}) => layout === ArticleSectionLayout.SLIDER ? `span 6` : `span 12`};
    }

    ${getMediaQuery(ScreenSize.LARGE)} {
      grid-column: ${({layout}) => layout === ArticleSectionLayout.FEATURED || layout === ArticleSectionLayout.LIST ? `span 6` : `span 4`};
      
      ${({layout}) => layout === ArticleSectionLayout.EDITORIAL ? 
        `
          grid-column: span 4;
          &:first-child{
            grid-column: span 8;
          }
        `
        : ``
      }

      ${({layout}) => layout === ArticleSectionLayout.EDITORIAL_REVERSE ? 
        `
          grid-row: 1;
          grid-column: 1 / span 4;
          &:first-child{
            grid-column: 5 / span 8;
          }
        `
        : ``
      }
    }
  }

`;